.Toastify__toast-container {
    width: max-content!important;
    min-width: 120px;
    text-align: center;
}

.Toastify__toast-container--top-center {
    top: 3rem;
}

@media only screen and (max-width : 480px) {
    .Toastify__toast-container {
        width: max-content!important;
        min-width: 120px;
        max-width: 80vw;
    }

    .Toastify__toast-container--top-center {
        top: 50vh;
        left: 50%;
        transform: translateX(-50%);
    }

    .Toastify__toast {
        margin-bottom: 0.5rem;
        border-radius: 0.25rem;
    }
}

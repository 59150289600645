#PageContainer .page-content {
    max-width: 1500px;
    padding: 0 12px;
}
.main-content .section-header__title {
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 16px;
    margin-top: 20px;
}
.main-content .grid__item{
    padding-left: 0 !important;
}
.main-content .section-header{
    margin-bottom: 0 !important;
}
.main-content ol{ list-style:decimal; }


.shippingHeader {
    display: none !important;
}

.main-content ul,
.main-content li {
    list-style: initial !important;
}

.section-header--404 {
    text-align: center;
}

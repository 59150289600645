/* @import "./Montserrat.css"; */
@import "./sizeGuide.css";
@import "./judgeme.css";
@import "./pagesInfo.css";
@import "./swiper.css";
@import "./udesk.css";
@import "./imagePicker.css";
@import "./star.css";
@import "./toast.css";
@import "./spinIos.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  line-height: 1.2;
  font-weight: 500;
}

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeSpeed;
  overflow-x: hidden;
  /* touch-action: none; */
}

.grecaptcha-badge {
  visibility: hidden;
}
.grecaptcha-badge__show{
  visibility: visible !important;
  bottom: 90px !important;
  z-index: 1000000 !important
}

@layer components {
  .button {
    @apply inline-block px-5 py-2.5 leading-none tracking-wider font-medium cursor-pointer;
  }
  .button--primary {
    @apply bg-black text-white;
  }
  .button--primary:disabled {
    @apply text-black bg-opacity-20 text-opacity-20 cursor-not-allowed;
  }

  .button--outline {
    @apply border-2;
  }

  .button.is-small {
    @apply text-tiny;
  }

  /* Badge */
  .badge {
    @apply absolute -top-3.5 -right-3.5 text-xl text-white font-semibold leading-0 min-w-min w-8 h-8 rounded-full overflow-hidden border border-white flex items-center justify-center transform scale-50;
  }
  /*color button*/
  .colorbtn {
    border: 2px solid #e5e5e5;
    overflow: hidden;
    @apply inline-block w-7 h-7 mr-2 mb-2 relative md:w-10 md:h-10 cursor-pointer md:mb-3;
  }

  .colorbtn:hover,.colorbtn.ischecked {
    border: 2px solid #000 !important;
    @apply shadow-none;
  }
  .colorbtn:hover::after,.colorbtn.ischecked::after {
    content: '' !important;
    border: 2px solid #fff !important;
    @apply block absolute top-1/2 left-1/2 w-full h-full transform -translate-x-1/2 -translate-y-1/2 rounded-bl-none;
  }
  .colorbtn.isdisabled {
    @apply opacity-90
  }
  .colorbtn.isdisabled::before {
    content: '' !important;
    @apply border-2 border-white absolute top-0 bottom-0 border-l-1 transform rotate-45 left-2.5 md:left-4;
  }

  /*size button*/
  .sizebtn {
    border-color: #ccc;
    @apply border-1 inline-flex justify-center items-center w-14 h-7 text-xss md:text-sm mb-2 mr-2 md:border-0 md:w-17 md:h-9 cursor-pointer md:mb-3;
  }
  .sizebtn:hover, .sizebtn.ischecked {
    /* border: 1px solid #000; */
    /* background-color: #000; */
    /* color:#fff; */
    border-color: #000;
    @apply border-0 md:border-2 bg-black md:bg-white text-white md:text-black;
  }
  .sizebtn.isdisabled{
    @apply text-black text-opacity-30 border-dotted border-black border-opacity-20;
  }
  /*splitbar*/
  .splitbar {
    @apply min-w-full bg-tag-grayf5 h-2.5;
  }
  .tag {
    @apply block text-xs px-1.5 font-semibold text-white leading-5;
  }
  .acenter{
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
  }
  .input {
    @apply inline-block p-2 border border-gray-300 outline-none w-full focus:border-black
  }
  .input--large {
    @apply text-sm
  }
}

.hero__text-shadow::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(rgba(0, 0, 0, .2) 0, transparent 60%);
  margin: -100px -200px -100px -200px;
  z-index: -1;
}
.noscrollbar{

}
.coupon--item {
  min-height: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  /* margin-right: 10px; */
  background: linear-gradient(134deg, #ff5226 0, #fa0000 100%);
  opacity: .96;
  color: #fff;
  /* font-size: 12px; */
  position: relative;
}
.coupon--item::before,.coupon--item::after{
  width: 7%;
  height: 0;
  padding-bottom: 7%;
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: '';
}
.coupon--item::before{
  left: -3.5%;
}
.coupon--item::after{
  right: -3.5%;
}
.nobar {
  -ms-overflow-style:none;
  overflow:-moz-scrollbars-none;
}
.nobar::-webkit-scrollbar{
  display:none;
}
.product-image:hover .second-img{
  overflow: hidden;
  opacity: 1;
}
.color-active{
  position: relative !important;
  border-color: #000 !important;
}
.color-active::after{
  display: block;
  content: '';
  background: transparent;
  border: 1px solid #ffffff;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
button, input, optgroup, select, textarea {
  background: none;
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
}
select, option {
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
}
select::-ms-expand, option::-ms-expand{ display: none; }
.arrow-down{
  display: block;
  width: 0;
  height: 0;
  right: 8px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid black;
}
.MuiBackdrop-root{
  background-color: rgba(0, 0, 0, .7) !important;
}
.send-email .MuiPaper-root{
  border-radius: 0;
}
.reviews-body p{
  margin-bottom: 12px;
}
.reviews-body p:last-child {
  margin-bottom: 0;
}

.fade-in-leave {
  animation: fade-in-leave 1.5s linear infinite alternate-reverse;
}

@keyframes fade-in-leave {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}

.sizeguide-wrapper {
    max-width: 460px;
}
.sizeChart-drawer__content {
    background: #f6f6f6;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 9999999999;
    display: none;
    overflow-y: scroll;
    width: 100%;
}

.sizeHeader {
    height: 48px;
    text-align: center;
    line-height: 48px;
    padding-left: 5px;
    font-size: 16px;
    font-weight: 400;
    background: #fff;
    position: relative;
}

.closeSize {
    padding-left: 10px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
}

.closeSize .icon{
    width: 20px;
    height: 20px;
}

.common-detail {
    padding: 26px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sg-size-newTitle .sg-title-ctn {
    border: 1px solid #222;
    display: flex;
}

.sg-size-newTitle li {
    width: 44px;
    height: 24px;
    line-height: 24px;
    color: #222;
    text-align: center;
    list-style-type: none;
}

.sg-size-newTitle li.title-active {
    background: #222;
    color: #fff;
}

.size-table-outer {
    /* padding: 13px; */
    width: 100%;
    padding: 13px;
    background: #fff;
    margin-bottom: 13px;
}

.sg-table-t {
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 6px;
}

.sg-table-base {
    /* border: 1px solid #e5e5e5; */
    text-align: center;
    background: #fff;
    border-spacing: 0;
    border-collapse: collapse;
    width: unset;
    font-size: 12px;
}

.sg-table-base tr {
    background: #f6f6f6;
    text-align: center;
}

.sg-table-base tbody>tr:first-child td {
    background: #f6f6f6;
    font-weight: 700;
}

.sg-table-base td {
    border: 1px solid #e5e5e5;
    text-align: center;
    height: 42px;
    padding: 0 12px;
}

.sg-table-base td:first-child {
    text-align: center;
    background: #f6f6f6;
    font-weight: 700;
    border: 1px solid #e5e5e5;
    padding: 0 8px;
}

.sg-table-notice {
    padding: 20px 0 14px;
    color: #999;
}

.measure-guide__header {
    border-top: 1px solid #e5e5e5;
    font-weight: 400;
    color: #222;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
}

.measure-guide .iconfont {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    font-family: iconfont !important;
    font-size: 12px;
    font-style: normal;
    width: 12px;
    height: 12px;
    line-height: 12px;
}

.measure-guide .iconfont-active {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transform-origin: center;
}

.displayNone {
    display: none !important;
}

.measure-guide__pic {
    display: flex;
}

.measure-guide__pic .measure-pic-guide {
    width: 60%;
    margin-right: 5%;
}

.measure-guide__pic .measure-pic0 {
    width: 30%;
}

.measure-wrap {
    margin-bottom: 13px;
}

.measure-wrap p:first-child {
    font-weight: 700;
}

.measure-wrap p {
    margin-bottom: 5px;
}

.size-table-outer .common-sizemeasure__sizeChart-size span {
    width: 60px;
    height: 28px;
    display: inline-block;
    line-height: 28px;
    border: 1px solid #e5e5e5;
    margin-bottom: 13px;
    margin-right: 9px;
    text-align: center;
    border-radius: 15px;
}

.size-table-outer .common-sizemeasure__sizeChart-size .common-sizemeasure__sizeChart-sizeSelect {
    border-color: #222;
}

.size-table-outer .common-sizemeasure__sizeChart-chart {
    background: #f6f6f6;
    padding-top: 13px;
}

.size-table-outer .common-sizemeasure__sizeChart-data {
    margin: 0 13px 13px;
    display: inline-block;
    color: #222;
    min-width: 30%;
}

.common-sizemeasure__sizeChart-chart .common-sizemeasure__sizeChart-wrap:first-child {
    display: block;
}

.common-sizemeasure__sizeChart-wrap {
    display: none;
}

.displayBlock {
    display: block !important;
}

.sizeDetails .sizeBox:first-child {
    display: block;
}

.sizeBox {
    display: none;
}

.sizeContainer {
    padding: 10px;
    background: #EFEFEF;
    color: #808080;
}

.sizeContainer .size-item {
    min-width: 155px;
    margin-bottom: 6px;
    display: inline-block;
}

.sizeContainer .size-item span {
    color: #000;
    margin-right: 10px;
}

/* .sizeAll .sizeContainer:first-child{
    display: block;
  } */
.sizeContainer {
    display: none;
}

.sizeAll .sizeContainer:first-child {
    display: block;
}

.seeMore {
    display: none;
}

.seeMore2 {
    /* font-weight: bold; */
    cursor: pointer;
}

.sizeAll {
    /*   display: none; */
}

#chat-button {
    bottom: 40px;
}

.sizeAllForm {
    margin-bottom: 10px;
}

.product-single__form {
    margin-top: 20px;

}
.sg-table-side .table-wrapper table {
    width: unset;
}
.sg-table-base table{
    width: unset;
}
.sg-table-base td, .sg-table-base tr{
    width: unset;
    /* white-space: nowrap; */
}
#sg-table-ctn-1{
    width: 100%;
    overflow-y:scroll;
    -webkit-overflow-scrolling: touch;
}

.common-sizemeasure__sizeChart-wrap .sg-table-t {
    margin-left: 13px;
    margin-bottom: 6px;
}

@media only screen and (min-width: 1024px) {
    .seeMore2 {
        margin-bottom: 20px;
    }

    .sizeChart-drawer__content {
        width: 460px;
        border-left: 1px solid #f6f6f6;
    }

}
.closeSize, .sg-title-ctn > li, .common-sizemeasure__sizeChart-size>span{
    cursor: pointer;
}
.table-item td{
    line-height: 3;
}
.table-item thead strong {
    display: flex;
    line-height: 1.5;
    justify-content: center;
    
}
.sg-table-base td{
    white-space: nowrap;
}
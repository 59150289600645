.reviews-star {
    --star-color: #000;
    --star-gray: #E5E5E5;
    margin: 2px;
    width: 28px;
    height: 28px;
}
.reviews-star > path {
    transform: scale(0.95);
    transform-origin: center center;
}
.reviews-star-outline {
    background: var(--star-color);
    color: #ffffff;
}
.reviews-star-fill {
    background: none;
    color: var(--star-color);
}
.star-half {
    background: linear-gradient(to right, var(--star-color) 50%, var(--star-gray) 50%)
}
.star-off {}
.star-on {}


.reviews-star-outline.star-on {}
.reviews-star-outline.star-off {
    background: var(--star-gray);
}
.reviews-star-outline.star-half {
}
.reviews-star-fill.star-on {}
.reviews-star-fill.star-off {
    color: var(--star-gray);
}
.reviews-star-fill.star-half {
    position: relative;
    background: transparent;
    -webkit-mask: linear-gradient(to right, var(--star-color) 50%, transparent 50%);
}
.reviews-star-fill.star-half:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 10;
    background: orange;
    -webkit-mask: linear-gradient(to right, transparent, #000);
}
.star-xxs .reviews-star {
    margin: 0 1px;
    width: 12px;
    height: 12px;
}
.star-xs .reviews-star {
    margin: 1px;
    width: 14px;
    height: 14px;
}
.star-sm .reviews-star {
    margin: 1px;
    width: 16px;
    height: 16px;
}
